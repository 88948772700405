import React, { useState, useEffect } from 'react';
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Card,
    Container
} from 'reactstrap';
import {Link, useNavigate} from 'react-router-dom';
import { default as Badges } from './Badges';
import { default as PlayerInfo } from './PlayerInfo';
import profilebanner from '../../img/christmaslogo.png';
import profilebanner2 from '../../img/christmaslogo2.png';
import profilebanner3 from '../../img/christmaslogo3.png';
import profilebanner4 from '../../img/christmaslogo4.png';
import { getTrackerToken } from '../../utils/GetToken.js';
import Loading from '../Loading.js';
import ArrowIcon from '../SVG/ArrowIcon.js';
import ServerBlock from "../Home/ServerBlock";
import Snowfall from "react-snowfall";

export default function Player(props) {

    const [loading, setLoading] = useState(true);
    const [isValid, setValid] = useState(true);
    const [playerData, setPlayerData] = useState([]);
    const [nameHistory, setNameHistory] = useState([]);
    const [bannerId, setBannerId] = useState(4);
    const [bannerStyle, setBannerStyle] = useState({paddingTop: '4rem', background: `url(${profilebanner4}) no-repeat center -20px`}); 
    const name = props.name;
    const navigate = useNavigate();

    useEffect(() => {
        switch (bannerId) {
            case 1:
                setBannerStyle({paddingTop: '8rem', background: `url(${profilebanner}) no-repeat center -20px`});
                break;
            case 2:
                setBannerStyle({paddingTop: '8rem', background: `url(${profilebanner2}) no-repeat center -20px`});
                break;
            case 3:
                setBannerStyle({paddingTop: '8rem', background: `url(${profilebanner3}) no-repeat center -20px`});
                break;
            case 4:
                setBannerStyle({paddingTop: '8rem', background: `url(${profilebanner4}) no-repeat center -20px`});
                break;
            default:
                setBannerStyle({paddingTop: '8rem', background: `url(${profilebanner4}) no-repeat center -20px`});
                break;
        }
    }, [bannerId])

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                
                const statsData = await fetch(`api/players/${encodeURIComponent(name)}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${getTrackerToken()}`
                    }
                }).then(r => r.json());

                setPlayerData(statsData);
                
                if (statsData.nameHistory) {
                    let reverseNameHistory = statsData.nameHistory.slice().reverse();
                    setNameHistory([...new Set(reverseNameHistory)]);
                }
                else {
                    setNameHistory([]);
                }
                
                setValid(true);
            }
            catch {
                setValid(false);
            }

            setLoading(false);
        }
        setBannerId(Math.round(Math.random() * 3) + 1);
        fetchData();
    }, [name]);

    const goBack = () => {
        navigate(-1);
    };


    let contents = loading ? <Loading message={`Loading data for ${name}`}/> : <PlayerInfo isValid={isValid} playerData={playerData} mapData={playerData.mapStats}
                                                                                           loading={loading} nameHistory={nameHistory} goBack={goBack} />;
    return (
        <>
        <Container>
            <Snowfall style={{height: '16rem'}}/>
            <div style={bannerStyle}>
                <Badges isValid={isValid} playerData={playerData}/>
            </div>
            {contents}
        </Container>
        </>
    );
}